.icon-select-widget {
    position: relative;
    width: 100%;
}

.selected-icon {
    display: inline-block;
    cursor: pointer;
    margin-bottom: 10px;
}

.icon-options {
    display: none; /* Скрываем опции по умолчанию */
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    background: white;
    border: 1px solid #ccc;
    padding: 5px;
    max-height: 250px;
    overflow-y: auto;
    width: 100%;
    position: absolute;
    z-index: 1000;
}

.icon-options.show {
    display: grid; /* Показываем опции в сетке, когда класс "show" добавлен */
}

.icon-radio-option {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.icon-radio-option img {
    width: 100%;
    height: 100%;
}

.icon-radio-option span {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #f0f0f0;
    text-align: center;
    line-height: 50px;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: pointer;
}

.empty-icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #f0f0f0; /* Серый фон */
    border: 1px solid #ccc;
    border-radius: 3px;
}
