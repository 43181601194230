/* Стили для области drag & drop */
.drop-area {
    border: 2px dashed #ccc;
    border-radius: 10px;
    width: 100%;
    height: 200px;
    padding: 20px;
    text-align: center;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
}

.drop-area.hover {
    background-color: #f0f8ff;
    border-color: #00008b;
}

.image-item img {
    object-fit: cover;
}

.drop-area p {
    margin: 0;
    font-size: 16px;
    color: #666;
}

/* Стили для галереи превью изображений */
#gallery, #current-images-gallery {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

/* Стили для обёртки изображения и кнопки удаления */
.img-wrapper {
    position: relative;
    display: inline-block;
}

.preview-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid #ccc;
}

/* Стили для кнопки удаления */
.remove-btn, .remove-current-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 0, 0, 0.7); /* Красный фон с прозрачностью */
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 16px; /* Размер крестика */
    line-height: 25px; /* Центрирование крестика вертикально */
    text-align: center;
    cursor: pointer;
    padding: 0;
    color: #ffffff; /* Белый крестик */
    transition: opacity 0.3s, background 0.3s;
}

.remove-btn:hover, .remove-current-btn:hover {
    opacity: 1; /* Убираем прозрачность при наведении */
    background: rgba(255, 0, 0, 1); /* Полностью красный фон при наведении */
}
